import React from 'react'
import ReactPlayer from 'react-player'
import styles from './Video.module.scss'

const PlayIcon = () => {
  return (
    <div className={styles.play__icon}>
      <svg viewBox="0 0 128 129" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64.0001 3.16675C30.1787 3.16675 2.66675 30.6814 2.66675 64.5001C2.66675 98.3187 30.1787 125.833 64.0001 125.833C97.8214 125.833 125.333 98.3187 125.333 64.5001C125.333 30.6814 97.8214 3.16675 64.0001 3.16675ZM86.8134 66.7188L54.8134 88.0521C54.3681 88.3481 53.8507 88.5001 53.3334 88.5001C52.9014 88.5001 52.4694 88.3961 52.0747 88.1854C51.2081 87.7214 50.6667 86.8174 50.6667 85.8334V43.1667C50.6667 42.1828 51.2081 41.2787 52.0747 40.8148C52.9387 40.3507 53.9948 40.3988 54.8108 40.9481L86.8108 62.2814C87.5548 62.7774 88.0001 63.6094 88.0001 64.5001C88.0001 65.3908 87.5547 66.2228 86.8134 66.7188Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

export default ({ video, previousPosted, ...rest }) => {
  return (
    <div className={styles.video__container}>
      <ReactPlayer
        controls={true}
        url={video}
        className={styles.video__realPlayer}
        playIcon={<PlayIcon />}
        {...rest}
      />
      {previousPosted && (
        <p
          className={styles.video__previousPosted}
          dangerouslySetInnerHTML={{ __html: previousPosted }}
        />
      )}
    </div>
  )
}
